/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/gsap@1.20.2/TweenLite.min.js
 * - /npm/gsap@1.20.2/TimelineLite.min.js
 * - /npm/gsap@1.20.2/CSSPlugin.min.js
 * - /npm/gsap@1.20.2/EasePack.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
